import { Controller } from '@hotwired/stimulus'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

export default class extends Controller {
  connect() {
    const footer = document.querySelector('footer:not([bfp])')
    if (!footer) return

    const fpPromise = FingerprintJS.load()

    fpPromise
      .then(fp => fp.get())
      .then(result => {

        const seed = Math.random().toString(16).substring(2, 7)
        const img = document.createElement('img')
        img.src = `/images/bfp-${result.visitorId}-${seed}.png`
        footer.appendChild(img)
        footer.toggleAttribute('bfp', true)
      })
  }
}
